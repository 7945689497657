import React from 'react'
import { uuid } from '../helpers/text'
import { usePubSub } from './usePubSub'

const MessageContext = React.createContext()

const MessageProvider = (props) => {
  return (
    <MessageContext.Provider value={usePubSub()} {...props} />
  )
}

const useMessages = ({ channel }) => {
  const context = React.useContext(MessageContext)

  const publish = React.useCallback((state) => {
    context.publish({ channel, state: { uuid: uuid(), ...state } })
  }, [channel, context])

  const clear = React.useCallback(() => {
    context.publish({ channel, state: null })
  }, [channel, context])

  const subscribe = React.useCallback(() => context.subscribe(channel), [context.subscribe, channel])

  return React.useMemo(() => ({
    ...context, publish, subscribe, clear,
  }), [publish, subscribe, clear, context])
}

export {
  MessageProvider,
  useMessages,
}
