import {
  BlankSlate, Block, Body, Button,
  Divider, Main, Shelf,
  sortBy,
  Text,
  useLocalStorage,
  useSearchState,
} from '@level'
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Routes, } from 'react-router-dom'

import { QueryProvider, useProject, useTeam } from '@app/hooks'
import { SharedSectionsProvider } from '../sharedSections/provider'
import { TemplateProvider } from './context'
import { ImportTemplateBanner } from './importTry'
import { TemplateCards } from './indexCards'
import { TemplateIndexFilters } from './indexFilters'
import { TemplateList } from './indexList'
import { useNewProjectTemplateDialog } from './new'
import { TemplateShow } from './template'
import { ActionButton } from './templateActionButton'

const templatesQuery = ({ teamId, projectId }) => `
templates(team: "${teamId}", project: "${projectId}") {
  id
  name
  slug
  type
  tags { color name }
  previewConfig {
    body
    variables
    sections {
      variables
      body
    }
  } published
  publishedAt
  createdAt
  updatedAt
}`

const Templates = ({
  newTemplate, ...rest
}) => {
  const [{ view }] = useSearchState()
  const View = !view || view === 'list' ? TemplateList : TemplateCards
  return rest.templates.length ? (
    <View {...rest} path="template" ActionButton={ActionButton} />
  ) : (
    <BlankSlate
      icon="template"
      heading="No Templates"
      description="Let's fix that! Add a new template to get started."
      buttonText="Add Template"
      buttonAction={newTemplate}
    />
  )
}

Templates.propTypes = {
  templates: PropTypes.array.isRequired,
  newTemplate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  watermark: PropTypes.bool.isRequired,
}

const filterTemplates = ({ templates, type, tags }) => {
  let filtered = templates
  if (type && type !== 'all') {
    filtered = filtered.filter((template) => template.type === type)
  }
  if (tags && tags !== 'all') {
    filtered = filtered.filter((template) => {
      const tagNames = template.tags.map(({ name }) => name) || []
      return !!tags.split(',').find(tag => tagNames.includes(tag))
    })
  }
  return filtered
}

const Index = () => {
  const { Project } = useProject()
  const { Team: { plan: { watermark } } } = useTeam()
  const [view, setView] = useLocalStorage('template-view', 'list')
  const [searchState, setSearchState] = useSearchState({ type: 'all', tags: [] })
  const { type, tags } = searchState
  const newTemplate = useNewProjectTemplateDialog()
  const providerVars = React.useMemo(() => ({ type, tags, newTemplate, watermark }), [type, tags, newTemplate])

  const onData = React.useCallback(({ data, ...rest }) => {
    const sorted = data.templates.sort(sortBy('name'))
    const tags = Array.from(data.templates.reduce((acc, template) => {
      // For each tag in the template, check if it's already in the set
      template.tags.forEach(tag => {
        // If no tag with the same name exists in the set, add it
        if (!Array.from(acc).some(({ name }) => name === tag.name)) {
          acc.add(tag)
        }
      });
      return acc;
    }, new Set()))
    return {
      templates: filterTemplates({ templates: sorted, ...rest }),
      newTemplate,
      type,
      tags,
    }
  }, [])

  React.useLayoutEffect(() => {
    if (view !== searchState.view) {
      setSearchState.merge({ view })
    }
  }, [searchState.view])

  return (
    <Main>
      <Block gap={5} space={8} style={{ backgroundColor: '#fff' }}>
        <Shelf valign="center" align="split">
          <Text tag="h2">Templates</Text>
          <Button size={4} theme="primary" text="Add Template" onClick={newTemplate} />
        </Shelf>
      </Block>
      <Divider />
      <TemplateIndexFilters {...{ view, setView, searchState, setSearchState }} />
      <ImportTemplateBanner />
      <Body scroll>
        <QueryProvider
          query={templatesQuery({ projectId: Project.slug, teamId: Project.team.slug })}
          contextVars={providerVars}
          onData={onData}
          element={Templates}
        />
      </Body>
    </Main>
  )
}

const TemplatesIndex = () => (
  <Routes>
    <Route index element={<Index />} />
    <Route
      path="template/:templateId/*"
      element={(
        <SharedSectionsProvider>
          <TemplateProvider><TemplateShow /></TemplateProvider>
        </SharedSectionsProvider>
      )}
    />
  </Routes>
)

export {
  TemplatesIndex
}
