import PropTypes from 'prop-types'
import {
  Grid, NavTabs, CheckMenuButton,
} from '@level'
import { useTemplateTags } from '@app/hooks'

const viewNavItems = [
  { icon: 'list-ul', label: 'List view', state: 'list' },
  { icon: 'grid2', label: 'Card view', state: 'cards' },
]

const viewTypes = [
  { text: 'All', value: 'all' },
  { text: 'Email', value: 'email' },
  { text: 'SMS', value: 'sms' },
]

const useTagList = () => {
  const { tags } = useTemplateTags()
  if (tags.length) {
    return [
      ...tags.map((tag) =>
        ({ text: tag.name, value: tag.name })
      )
    ]
  }
}

const TemplateIndexFilters = ({
  view, setView,
  searchState, setSearchState,
}) => {
  const tagList = useTagList()
  const defaultTags = !searchState.tags ? [] : searchState.tags?.split(',')
  return (
    <Grid space={[6, 8, 0]} gap={4} templateColumns="min-content min-content 1fr min-content" valign="center">
      <CheckMenuButton
        prefix="Templates:"
        onChange={(s) => setSearchState.merge({ type: s })}
        defaultValue={searchState.type}
        checkIcon="circle-dot"
        items={viewTypes}
        theme="menu"
        nowrap
      />
      {tagList ? (<CheckMenuButton
        prefix="Tags:"
        onChange={(s) => setSearchState.merge({ tags: s })}
        defaultValue={defaultTags}
        items={tagList}
        theme="menu"
        noneSelected="Tags: Any"
        nowrap
      />) : <span />}
      <span />
      <NavTabs
        setState={(v) => { setSearchState.merge({ view: v }); setView(v) }}
        state={view}
        space={0}
        itemSpace={[4, 6]}
        theme="radio"
        navItems={viewNavItems}
      />
    </Grid>
  )
}

TemplateIndexFilters.propTypes = {
  searchState: PropTypes.object,
  setSearchState: PropTypes.func,
  view: PropTypes.string,
  setView: PropTypes.func,
}

export { TemplateIndexFilters }
