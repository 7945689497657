import PropTypes from 'prop-types'

import { Grid, TemplatePreviewCard } from '@level'

const TemplateCards = ({
  templates, watermark, refresh, ActionButton, path,
}) => (
  <Grid
    gap={8}
    columnMin={250}
    fill
  >
    {templates.map((template) => (
      <TemplatePreviewCard
        published={template.published}
        publishedAt={template.publishedAt}
        title={template.name}
        tags={template.tags}
        updatedAt={template.updatedAt || template.createdAt}
        to={`${path}/${template.slug}`}
        key={template.id}
        zoom={template.type === 'sms' ? 0.8 : 0.37}
        type={template.type}
        actionButton={<ActionButton template={template} refresh={refresh} />}
        config={template.previewConfig}
        hooks={{ watermark }}
      />
    ))}
  </Grid>
)

TemplateCards.propTypes = {
  templates: PropTypes.array.isRequired,
  watermark: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  ActionButton: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
}

export {
  TemplateCards,
}
