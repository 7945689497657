/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { create } from 'zustand'
import { useProject } from './useProject'
import { useQuery } from './useQuery'

const tagsQuery = ({ Project }) => (
  `tags(team: "${Project.team.slug}", project: "${Project.slug}") { name }`
)

const useTagStore = create((set, get) => {
  return {
    tags: [],
    setTags: (tags) => set({ tags }),
    addTag: (tag) => {
      if (!get().tags.find(({ name }) => name === tag.name)) {
        set((state) => ({ tags: [...state.tags, tag] }))
      }
    },
    getTags: () => get().tags,
  }
})

const useTemplateTags = () => {
  const { Project, tags: projectTags } = useProject()
  const [fetchTags, { data, queryCount }] = useQuery(tagsQuery({ Project }))
  const store = useTagStore()

  React.useEffect(() => {
    if (data?.tags) {
      store.setTags(data.tags)
    }
  }, [data?.tags])

  React.useEffect(() => {
    if (!store.tags.length && projectTags) {
      store.setTags(projectTags)
    }
  }, [projectTags])

  React.useEffect(() => {
    if (!store.tags.length && !queryCount) { fetchTags() }
  }, [fetchTags, queryCount])

  return {
    refresh: fetchTags,
    ...store,
  }
}

export {
  useTemplateTags,
  tagsQuery,
}
