import React from 'react'
import {
  ButtonShelf, MenuButton, Button,
  useCopyText, renderTemplate, exportTemplate,
  useLiveDate, timeAgoOrDate,
  useNetworkStatus,
} from '@level'

import { useProject } from '@app/hooks'
import { useTemplate } from './context'
import { useCopyTheme, usePasteTheme } from './hooks'

const TemplateMenuActions = () => {
  const { copyText } = useCopyText()
  const { online } = useNetworkStatus()

  const context = useTemplate()
  const {
    revertTemplate,
    deleteTemplate,
    renameTemplate,
    copyTemplate,
    publishTemplate,
    getData,
    editTemplateTags,
    template: { slug },
    refresh,
  } = context
  const copyTheme = useCopyTheme(getData())
  const pasteTheme = usePasteTheme(getData())

  const { Project } = useProject()

  const { template: { publishedAt, published } } = getData()

  const date = useLiveDate({ date: publishedAt, format: timeAgoOrDate })
  const copyTemplateHtml = () => {
    const { template: { previewConfig: config } } = getData()
    return copyText(renderTemplate({ config, mode: 'preview-html', type: 'email' }))
  }

  const exportTemplateJSON = () => {
    const { template: { previewConfig: config } } = getData()
    copyText(JSON.stringify(exportTemplate({ config, type: 'email' }), null, 2))
  }

  const menuActions = React.useMemo(() => {
    const { template } = getData()
    if (!template) return []
    const actions = [
      {
        text: 'Rename',
        icon: 'pen-field',
        onSelect: renameTemplate,
      },
      {
        text: 'Duplicate',
        onSelect: copyTemplate,
        icon: 'copy',
      },
      {
        text: 'Manage Tags',
        onSelect: editTemplateTags,
        icon: 'tags',
      },
    ]
    if (template.type === 'email' && copyTheme) {
      actions.push({
        text: 'Copy Theme',
        onSelect: copyTheme,
        icon: 'share-from-square',
      })
    }
    if (template.type === 'email' && pasteTheme) {
      actions.push({
        text: 'Apply Theme',
        onSelect: async () => {
          await pasteTheme()
          refresh()
        },
        icon: 'fill-drip',
      })
    }

    if (template.type === 'email' && import.meta.env.VITE_NODE_ENV === 'development') {
      actions.push({
        text: 'Copy Template HTML',
        icon: 'clipboard',
        onSelect: copyTemplateHtml,
        divider: true,
      })
      actions.push({
        text: 'Export Template JSON',
        icon: 'clipboard',
        onSelect: exportTemplateJSON,
      })
    }

    if (template.publishedAt && !template.published) {
      const previewLink = `/template?teamId=${Project.team.slug}&projectId=${Project.slug}&templateId=${slug}&view=live`
      actions.push({
        text: 'View Live Version',
        onClick: (event) => {
          // If user intends to open preview in a tab, allow it, otherwise use a popup window.
          const newTab = event.ctrlKey || event.metaKey || event.button === 1
          const previewWindow = window.open(previewLink, 'liveTemplate', newTab ? null : 'width=850,height=850')
          previewWindow.isChild = true
        },
        icon: 'up-right-from-square',
      })
      actions.push({
        text: `Revert (${date})`,
        onSelect: revertTemplate,
        icon: 'arrow-rotate-left',
      })
    }
    actions.push({
      text: 'Delete',
      onSelect: deleteTemplate,
      icon: 'trash',
      destructive: true,
      divider: true,
    })

    return actions
  }, [
    renameTemplate,
    deleteTemplate,
    copyTemplate,
    pasteTheme,
  ])

  return (
    <ButtonShelf>
      <Button text="Publish" theme={published ? 'default' : 'primary-green'} onClick={publishTemplate} disabled={published || !online} />
      <MenuButton items={menuActions} menuIconGap={4} size={4} icon="gear-solid" label="Template options" />
    </ButtonShelf>
  )
}

export {
  TemplateMenuActions,
}
