import PropTypes from 'prop-types'

import {
  Grid, Block, Shelf, Text, Spacer, Button, Stack, Editor,
  formatDateTime,
} from '@level'

import { ErrorNameCode } from './helpers'

const ErrorHeader = ({
  close,
  selected: error,
  selectNext,
  selectPrevious,
}) => (
  <>
    <Shelf gap={4} space={[7, 7, 0]} valign="top">
      <Stack gap={4}>
        <Text tag="h3"><ErrorNameCode error={error.errorMessage} /></Text>
        <Text tag="p" color="text-light">{error.errorDescription}</Text>
      </Stack>
      <Spacer grow />
      <Button theme="wrapper" label="close" icon={{ name: 'x-light', size: 4 }} onClick={close} />
    </Shelf>
    <Shelf space={7} className="message-log-item-meta" align="split" valign="top">
      <Shelf gap={6}>
        <Grid columns={2} gap={6} rowGap={2}>
          <Text><strong>code:</strong> {error.errorCode}</Text>
          <Text><strong>date:</strong> {formatDateTime(error.createdAt, { utc: true })}</Text>
          <Text><strong>path:</strong> {error.path}</Text>
          {error.sdkVersion ? (
            <Text><strong>SDK Version:</strong> {error.sdkVersion}</Text>
          ) : null}
        </Grid>
      </Shelf>
      <Shelf>
        {error.templateLink ? <Button icon="pen" to={error.templateLink} size={4} text="Edit Template" /> : null}
        <Spacer space={4} />
        <Button onClick={selectPrevious} icon="angle-left" label="previous message" disabled={!selectPrevious} />
        <Button onClick={selectNext} icon="angle-right" label="next message" disabled={!selectNext} />
      </Shelf>
    </Shelf>
  </>
)

const ErrorModal = ({
  dialogContainerRef,
  ...rest
}) => (
  rest.selected ? <>
    <ErrorHeader {...rest} />
    <Block space={7} style={{ backgroundColor: '#F5F6F7' }} maxHeight rootRef={dialogContainerRef}>
      <Stack gap={5}>
        <Text tag="h4">Message Body</Text>
        {Object.keys(rest.selected.body).length > 0 ? (
          <Editor content={JSON.stringify(rest.selected.body, null, 2)} lang="json" />
        ) : (
          <pre className="level-pre">{rest.selected.rawBody}</pre>
        )}
      </Stack>
    </Block>
  </> : null
)

ErrorModal.propTypes = {
  // Shallow messsage from table data
  selected: PropTypes.object.isRequired,
  dialogContainerRef: PropTypes.object.isRequired,
}

export {
  ErrorModal
}
