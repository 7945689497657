import React from 'react'
import PropTypes from 'prop-types'

import {
  gapSize,
  alignItemsStyle,
  flexFlow,
  alignPropTypes,
  spaceToSize,
} from '../../helpers'

import './shelf.scss'

const Shelf = React.forwardRef(function Shelf({
  reverse,
  gap = 0,
  rowGap,
  tag: Tag = 'div',
  className,
  style: propsStyle = {},
  nowrap = false,
  wrapReverse = (!nowrap && reverse),
  theme,
  place,
  align,
  valign,
  space = 0,
  inline = false,
  ...rest
}, ref) {
  const style = {
    display: inline ? 'inline-flex' : 'flex',
    placeItems: place,
    ...propsStyle,
    ...flexFlow({ nowrap, reverse, wrapReverse }),
    ...alignItemsStyle({ align, valign }),
    ...gapSize({ gap, rowGap }),
    padding: space ? spaceToSize(space) : null,
  }

  return (
    <Tag
      className={`level-shelf${className ? ` ${className}` : ''}`}
      style={style}
      ref={ref}
      data-theme={theme}
      {...rest}
    />
  )
})

Shelf.propTypes = {
  columns: PropTypes.number,
  ...alignPropTypes,
  nowrap: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  gap: gapSize.propTypes.gap,
  rowGap: gapSize.propTypes.gap,
  reverse: PropTypes.bool,
  wrapReverse: PropTypes.bool,
  tag: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.string,
  space: spaceToSize.propTypes.space,
  inline: PropTypes.bool,
}

const Spacer = ({
  grow = false,
  space = 0,
  width = 0,
  height = 0,
}) => (
  <div
    className="shelf-spacer"
    aria-hidden
    style={{
      flex: grow ? '1 0 auto' : '0 1 auto',
      width: space ? spaceToSize(width || space) : '0',
      height: height ? spaceToSize(height) : '0',
    }}
  />
)

Spacer.propTypes = {
  align: PropTypes.string,
  grow: PropTypes.bool,
  space: spaceToSize.propTypes.space,
  ofType: PropTypes.string,
  showAboveGridWidth: PropTypes.number,
  showBelowGridWidth: PropTypes.number,
  showBetweenGridWidths: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
}

export { Shelf, Spacer }
