import React from 'react'

import {
  ScrollPanel, Drawer, Block, Divider, NavTabs, Stack, Text, Link,
  Notice, SelectMenu, Template, removeNestedKeys,
} from '@level'

import { useProfile, useProject, useTeam } from '@app/hooks'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useTemplate } from '../context'
import { Snippet } from './integrations'

const examplesNav = [
  { text: 'cURL', to: './curl' },
  { text: 'JS', to: './js' },
  { text: 'Node', to: './node' },
  { text: 'Ruby', to: './ruby' },
  { text: 'Python', to: './python' },
]

const SelectToken = ({ tokens, ...props }) => {
  const options = React.useMemo(() => tokens.map(({ name, token, testMode }) => ({
    text: testMode ? `${name} token (sandbox)` : `${name} token (production)`,
    value: token,
  })), [tokens])
  return <SelectMenu label={false} name="token" options={options} {...props} sameWidth />
}

const ProviderConfigured = ({
  type, allowSms, teamUrl, projectUrl,
}) => {
  const noticeProps = {
    icon: { name: 'exclamation-triangle', gap: 4 },
    space: 4,
    flush: 4,
  }
  if (type === 'email') {
    return <Notice {...noticeProps}>Please <Link to={teamUrl('mail-settings')}>configure email delivery</Link> for your project.</Notice>
  }
  if (type === 'sms') {
    if (allowSms) {
      return <Notice {...noticeProps}>Please <Link to={projectUrl('settings/integrations')}>configure an sms provider</Link> for your project.</Notice>
    }
    return <Notice {...noticeProps}>To send SMS messages you&rsquo;ll need to <Link to={teamUrl('settings')}>upgrade your plan</Link>.</Notice>
  }
  return null
}

const DeployCode = ({ sandboxLink, tokens, data, hasTheme }) => {
  const testToken = tokens.find((token) => token.active && token.testMode) || tokens[0]
  const sandboxTokens = React.useMemo(() => (
    tokens ? tokens.filter(({ testMode }) => testMode).map((t) => t.token) : []
  ), [])

  const [token, setToken] = React.useState(testToken?.token || null)
  const isSandbox = token && sandboxTokens.includes(token)

  const snippetProps = {
    data,
    token,
  }

  return (
    <>
      <NavTabs navItems={examplesNav} border />
      <Block space={7}>
        <Stack gap={5}>
          {tokens.length > 1 ? (
            <SelectToken
              tokens={tokens}
              onChange={setToken}
              defaultValue={token}
            />
          ) : null}
          <Routes>
            <Route path="*" element={<Navigate to="curl" />} />
            <Route path="curl" element={<Snippet type="curl" {...snippetProps} />} />
            <Route path="js" element={<Snippet type="js" {...snippetProps} />} />
            <Route path="node" element={<Snippet type="node" {...snippetProps} />} />
            <Route path="ruby" element={<Snippet type="ruby" {...snippetProps} />} />
            <Route path="python" element={<Snippet type="python" {...snippetProps} />} />
          </Routes>
          {hasTheme ? (
            <Notice icon={{ name: 'fill-drip', gap: 5, size: 4 }} space={4} theme="aqua">
              Template themes can be overriden in the message API. <Link to="https://docs.enveloop.com/enveloop-api/core-api-endpoints/post-messages#theme-optional" target="_blank">learn more</Link>.
            </Notice>
          ) : null}
          {isSandbox && sandboxLink ? (
            <Notice icon={{ name: 'lightbulb', gap: 5, size: 4 }} space={4} theme="purple">
              When using a sandbox API key,
              messages are not sent, but are logged in the {sandboxLink}.
            </Notice>
          ) : null}
        </Stack>
      </Block>
    </>
  )
}

const DeployDrawer = () => {
  const { Profile } = useProfile()
  const { template } = useTemplate()
  const hasTheme = React.useRef(false)
  const { url: teamUrl, Team } = useTeam()
  const {
    Project: {
      mailProvider, smsProvider,
      team: { plan: { allowSms } },
    },
    url: projectUrl,
    tokens,
  } = useProject()

  const { defaultSubject, defaultFrom } = template.previewConfig.variables.config || {}
  const { type } = template

  const provider = {
    email: mailProvider || Team.senderIdentities?.length,
    sms: smsProvider,
  }[type]

  const to = {
    email: Profile.email,
    sms: Profile.phone,
  }[type] || null

  const payloadVariables = React.useMemo(() => {
    const vars = { to }

    // If no defaultFrom is set, default to an empty string to indicate it must be set in the API.
    if (!defaultFrom && !Team[`defaultFrom${type === 'email' ? 'Email' : 'Sms'}`]) vars.from = ''
    if (type === 'email' && !defaultSubject) vars.subject = ''

    vars.template = template?.slug
    vars.templateVariables = Template.getTemplateVars({ template })
    hasTheme.current = vars.templateVariables.theme || vars.templateVariables._theme
    delete vars.templateVariables._theme
    delete vars.templateVariables.theme

    return vars
  }, [template?.previewTemplateVariables, defaultFrom, defaultSubject])

  return (
    <Drawer>
      <ScrollPanel>
        <Block space={7}>
          <Stack gap={5}>
            <Text tag="h3">Deploy</Text>
            <Text tag="p">Copy a snippet to begin sending messages with this template.</Text>
            {!provider ? (
              <ProviderConfigured {...{
                type, allowSms, projectUrl, teamUrl,
              }}
              />
            ) : null}
          </Stack>
        </Block>
        <Divider />
        <DeployCode
          hasTheme={hasTheme.current}
          tokens={tokens}
          data={payloadVariables}
          template={template}
          sandboxLink={<Link to={projectUrl('messages/sandbox')}>sandbox message Log</Link>}
        />
      </ScrollPanel>
    </Drawer>
  )
}

export {
  DeployCode,
  DeployDrawer,
}
