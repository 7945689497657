import PropTypes from 'prop-types'
import React from 'react'
import { camelToTitle } from '../../helpers/text'
import { useFormRef } from '../../hooks/useForm'
import { Column } from '../Column'
import { HiddenInput, Input } from '../Input'
import { Shelf } from '../Shelf'

const SliderDisplayValue = ({
  name, displayValue, width = '3em',
}) => {
  const { watch } = useFormRef()
  const watchName = typeof displayValue === 'string' ? displayValue : name
  // Set preview from input value
  let value = watch({ name: watchName })
  if (typeof displayValue === 'function') value = displayValue(value)

  return (
    <div style={{ minWidth: width, paddingTop: '15px' }}>{value}</div>
  )
}

SliderDisplayValue.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
}

// Renders a slider (range) input with optional preview of the value
// Set an optional displayValueWidth so it doesn't change size with different values
// displayValue can be:
//   - a callback function to set the text (great for aliasing values or adding units)
//   - false: to disable the displayValue preview
const SliderInput = React.forwardRef(function SliderInput({
  name,
  unit,
  displayValue,
  displayValueWidth,
  defaultValue: defaultValueProp,
  label,
  onChange: onChangeProp,
  ...rest
}, ref) {
  const {
    getValues, setValue, resetRef,
  } = useFormRef()
  const sliderName = (unit || displayValue) ? `${name}-slider-control` : name
  const defaultValue = typeof defaultValueProp === 'string'
    ? Number.parseInt(defaultValueProp, 10) : defaultValueProp

  React.useEffect(() => {
    if (defaultValueProp !== getValues(sliderName)) {
      setValue(sliderName, defaultValue)
    }
    if (unit && defaultValueProp !== `${defaultValue}${unit}`) {
      setValue(name, `${defaultValue}${unit}`)
    }
  }, [defaultValue, defaultValueProp, sliderName, unit])

  // When slider is reset ensure that value is set with units
  React.useEffect(() => {
    if (resetRef.current && unit) {
      setValue(name, `${defaultValue}${unit}`)
    }
  }, [resetRef.current])

  const onChange = React.useCallback((event) => {
    if (unit) {
      const unitValue = `${event.target.value}${unit}`
      setValue(name, unitValue, { shouldDirty: true })
    }
    onChangeProp?.(event)
  }, [unit])

  if (displayValue === false && !unit) {
    return <Input name={name} type="range" {...rest} ref={ref} />
  }

  return (
    <Shelf gap={4} valign="center">
      <Column grow>
        {unit ? <HiddenInput name={name} defaultValue={`${defaultValue}${unit}`} /> : null}
        <Input
          type="range"
          name={sliderName}
          label={label !== false ? (label || camelToTitle(name)) : label}
          onChange={onChange}
          ref={ref}
          {...rest}
        />
      </Column>
      <SliderDisplayValue
        name={unit ? name : sliderName}
        displayValue={displayValue}
        width={displayValueWidth}
      />
    </Shelf>
  )
})

SliderInput.propTypes = {
  ...Input.propTypes,
  displayValueWidth: PropTypes.string,
  displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
}

export {
  SliderInput
}
