import * as Ariakit from '@ariakit/react'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Text } from '../Text'
import { Shelf } from '../Shelf'
import { Icon } from '../Icon'
import { spaceToSize } from '../../helpers'
import './menu-button.scss'

const MenuButton = ({
  items, menuItemSpace = [4, 6], menuIconGap = 4, sameWidth, ...rest
} = {}) => {
  const navigate = useNavigate()
  return (
    <Ariakit.MenuProvider>
      <Button as={Ariakit.MenuButton} {...rest} />
      <Ariakit.Portal>
        <Ariakit.Menu gutter={4} className='level-menu' sameWidth={sameWidth}>
          {items.map(({
            to, text, onSelect, divider, heading, key, children, destructive, ...props
          }) => {
            return (
              <React.Fragment key={key || text}>
                {heading ? (<Text as={Ariakit.MenuHeading} size={0} space={menuItemSpace}>{heading}</Text>) : null}
                {divider ? <Divider space={[3, 0]} /> : null}
                <Ariakit.MenuItem
                  onClick={to ? () => navigate(to) : onSelect}
                  render={<Text data-destructive={destructive} space={menuItemSpace} iconGap={menuIconGap} {...props} />}
                  className="level-menu-item"
                >
                  {children || text}
                </Ariakit.MenuItem>
              </React.Fragment>
            )
          })}
        </Ariakit.Menu>
      </Ariakit.Portal>
    </Ariakit.MenuProvider>
  )
}

MenuButton.propTypes = {
  items: PropTypes.array.isRequired,
  menuItemSpace: spaceToSize.propTypes.space,
  menuIconGap: PropTypes.number,
  sameWidth: PropTypes.bool,
}

const getSelectedItems = ({ values, items }) => {
  const vals = Array.isArray(values) ? values : [values]
  return items.filter(({ value }) => vals.includes(value))
}

const buttonText = ({ values, items, prefix, noneSelected }) => {
  const selected = getSelectedItems({ values, items })
  if (!selected.length) return noneSelected
  const text = selected.map(({ children, text }) => children || text)
  return (prefix) ? <>{prefix} {text.join(', ')}</> : text
}

const isSelected = ({ values, value }) => {
  return !!(Array.isArray(values) ? values : [values]).includes(value)
}

const CheckMenuButton = ({
  items, menuItemSpace, menuIconGap = 4, sameWidth,
  defaultValue, name = 'value', prefix, onChange, check = true, checkIcon = "check",
  noneSelected = 'Select One',
  ...rest
}) => {
  const [values, setValue] = React.useState({ [name]: defaultValue });
  // If a defaultValue is an array, the internal value is additive,
  // If it is a string, it behaves like a radio button
  const MenuItem = React.useMemo(() => {
    if (name && typeof defaultValue === 'string') return Ariakit.MenuItemRadio
    if (name && Array.isArray(defaultValue)) return Ariakit.MenuItemCheckbox
    return Ariakit.MenuItem
  }, [defaultValue, name])

  const navigate = useNavigate()

  return (
    <Ariakit.MenuProvider
      values={values}
      setValues={(v) => {
        setValue(v)
        onChange?.(v[name])
      }}
      defaultvalues={{ [name]: defaultValue }}
    >
      <Button
        as={Ariakit.MenuButton}
        text={buttonText({ values: values[name], prefix, items, noneSelected })}
        iconAfter="angle-up-and-down"
        {...rest}
      />
      <Ariakit.Portal>
        <Ariakit.Menu
          gutter={4}
          className='level-menu'
          sameWidth={sameWidth}
        >
          {items.map(({
            to, text, value = to, divider, heading, key, children, ...props
          }) => {
            return (
              <React.Fragment key={key || value}>
                {heading ? (<Text as={Ariakit.MenuHeading} size={0} space={menuItemSpace}>{heading}</Text>) : null}
                {divider ? <Divider space={[3, 0]} /> : null}
                <MenuItem
                  name={name}
                  value={value}
                  render={<Shelf gap={4} space={menuItemSpace} />}
                  className="level-menu-item-check"
                  onClick={to ? () => navigate(to, { replace: true }) : null}
                >
                  <Shelf gap={menuIconGap} valign="center">
                    {check ? <Icon name={checkIcon} fill={isSelected({ values: values[name], value }) ? null : 'transparent'} aria-hidden /> : null}
                    <Text {...props}>
                      {children || text}
                    </Text>
                  </Shelf>
                </MenuItem>
              </React.Fragment>
            )
          })}
        </Ariakit.Menu>
      </Ariakit.Portal>
    </Ariakit.MenuProvider>
  )
}

CheckMenuButton.propTypes = {
  items: PropTypes.array.isRequired,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  menuItemSpace: spaceToSize.propTypes.space,
  menuIconGap: PropTypes.number,
  sameWidth: PropTypes.bool,
  check: PropTypes.bool,
  checkIcon: PropTypes.string,
  noneSelected: PropTypes.string,
}

export { MenuButton, CheckMenuButton }
