import PropTypes from 'prop-types'

import { toColor } from '../../helpers/color'
import { spaceToSize } from '../../helpers/space'

const Banner = ({
  background = 'neutral-50',
  border = 'neutral-100',
  color = 'text',
  space = [4, 6],
  ...props
}) => {

  return (
    <div
      className="level-banner"
      style={{
        borderBottom: `1px solid ${toColor(border)}`,
        background: toColor(background),
        color: toColor(color),
        padding: space ? spaceToSize(space) : null,
      }}
      {...props}
    />
  )
}

Banner.propTypes = {
  background: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  space: spaceToSize.propTypes.space,
}

export {
  Banner,
}

