import PropTypes from 'prop-types'
import * as Ariakit from "@ariakit/react"
import { matchSorter } from "match-sorter"
import * as React from "react"
import { Input } from '../Input'
import { useFormRef } from '../../hooks'
import {
  getAnchorRect,
  getSearchValue,
  getTrigger,
  getTriggerOffset,
  replaceValue
} from './utils'
import './autocomplete-input.css'

const getValue = (listValue, trigger, list, defaultToAlphanumeric) => {
  const currentList = defaultToAlphanumeric
    ? list // use the whole list if alphanumeric
    : (list.find(item => item.trigger === trigger).items || [])

  return currentList.find(i => i.listValue === listValue)?.value
}

export const AutoCompleteInput = ({
  name,
  list = [],
  defaultValue = "",
  separator = ' ',
  ...rest
}) => {
  const { control, watch, setValue } = useFormRef()
  const ref = React.useRef()
  const value = watch({ name }) || ''

  React.useEffect(() => {
    setValue(name, defaultValue, { shouldDirty: true })
  }, [])

  const [trigger, setTrigger] = React.useState(null);
  const [caretOffset, setCaretOffset] = React.useState(null);

  const triggers = React.useMemo(() =>
    list.filter(item => item.trigger).map(item => item.trigger),
    [list]
  );

  const defaultToAlphanumeric = !triggers.length;
  const combobox = Ariakit.useComboboxStore();
  const searchValue = Ariakit.useStoreState(combobox, "value");
  const deferredSearchValue = React.useDeferredValue(searchValue);

  const matches = React.useMemo(() => {
    let currentList = defaultToAlphanumeric
      ? list
      : (trigger ? list.find(item => item.trigger === trigger)?.items || [] : []);

    currentList = currentList.map(i => i.listValue);
    return deferredSearchValue
      ? matchSorter(currentList, deferredSearchValue).slice(0, 10)
      : [];
  }, [defaultToAlphanumeric, list, trigger, deferredSearchValue]);

  const hasMatches = !!matches.length;

  React.useLayoutEffect(() => {
    combobox.setOpen(hasMatches);
  }, [combobox, hasMatches]);

  React.useLayoutEffect(() => {
    if (caretOffset != null) {
      ref.current?.setSelectionRange(caretOffset, caretOffset);
    }
  }, [caretOffset]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(name, newValue, { shouldDirty: true })

    const trigger = getTrigger(event.target, triggers, defaultToAlphanumeric);
    const searchValue = getSearchValue(event.target, triggers, defaultToAlphanumeric);

    if (trigger) {
      setTrigger(trigger);
      combobox.show();
    } else if (!searchValue) {
      setTrigger(null);
      combobox.hide();
    }

    combobox.setValue(searchValue);
  }

  const handleItemClick = (itemValue) => () => {
    const element = ref.current;
    if (!element) return;

    const offset = getTriggerOffset(element, triggers, defaultToAlphanumeric);
    const displayValue = getValue(itemValue, trigger, list, defaultToAlphanumeric);
    if (!displayValue) return;

    const newValue = replaceValue(offset, searchValue, displayValue, separator)(element.value);
    setValue(name, newValue, { shouldDirty: true })
    setTrigger(null);
    setCaretOffset(offset + displayValue.length + 1);
  };

  return (
    <>
      <Ariakit.Combobox
        store={combobox}
        autoSelect
        value={value}
        showOnClick={false}
        showOnChange={false}
        showOnKeyPress={false}
        setValueOnChange={false}
        className="combobox"
        render={
          <Input
            name={name}
            ref={ref}
            value={value}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                combobox.hide();
              }
            }}
            {...rest}
          />
        }
      />
      <Ariakit.Portal>
        <Ariakit.ComboboxPopover
          store={combobox}
          hidden={!hasMatches}
          unmountOnHide
          gutter={8}
          sameWidth
          getAnchorRect={() => ref.current && ref.current.tagName.match(/textarea/i) && getAnchorRect(ref.current, triggers, defaultToAlphanumeric)}
          className="popover"
        >
          {matches.map((value) => (
            <Ariakit.ComboboxItem
              key={value}
              value={value}
              // focusOnHover
              onClick={handleItemClick(value)}
              className="combobox-item"
            >
              {value}
            </Ariakit.ComboboxItem>
          ))}
        </Ariakit.ComboboxPopover>
      </Ariakit.Portal>
    </>
  );
};

AutoCompleteInput.propTypes = {
  name: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  separator: PropTypes.string,
  defaultValue: PropTypes.string,
}
