import { stateChannel } from './useState'

const useDialogStore = stateChannel('dialog')
const useDialogChannel = () => ({
  publish: useDialogStore(state => state.publish),
  data: useDialogStore(state => state.dialog),
  remove: useDialogStore(state => state.remove),
})

const useDialog = () => {
  const { publish } = useDialogChannel()

  const sendDialog = async ({
    onConfirm: confirm,
    onCancel: cancel,
    ...rest
  }) => (
    new Promise((resolve, reject) => {
      const onConfirm = confirm ? async (...args) => {
        const result = await confirm(...args)
        if (result?.errors) {
          throw result.errors
        } resolve(result)
        return result
      } : confirm

      const onCancel = async () => {
        await cancel?.()
        return reject()
      }

      publish({
        onConfirm, onCancel, ...rest,
      })
    }).catch(() => { })
  )

  return sendDialog

}

export {
  useDialog,
  useDialogChannel,
}
