import {
  Input, P,
  copyObject,
  useDialog,
  renderSection,
  embedSectionTheme,
} from '@level'
import { useFormQuery, useProject } from '@app/hooks'

import {
  createSharedSectionQuery,
  deleteSharedSectionQuery,
  revertSharedSectionQuery,
  publishSharedSectionQuery,
  updateSectionQuery,
} from './queries'

const usePublishSharedSection = ({ sharedSection }) => {
  const { onSubmit } = useFormQuery({
    query: publishSharedSectionQuery(sharedSection),
  })
  return onSubmit
}

const useRevertSharedSection = ({ sharedSection }) => {
  const sendDialog = useDialog()

  const { onSubmit: onConfirm } = useFormQuery({
    query: revertSharedSectionQuery(sharedSection),
  })

  return () => sendDialog({
    onConfirm,
    title: 'Revert Changes?',
    content: `This will revert all changes to "${sharedSection.name}" since the last publish. Are you sure?`,
    confirmText: 'Revert Changes',
    destructive: true,
  })
}

const useRenameSharedSection = ({ sharedSection, ...rest }) => {
  const sendDialog = useDialog()

  const { onSubmit: onConfirm } = useFormQuery({
    query: ({ name }) => {
      const section = copyObject(sharedSection)
      section.name = name
      section.previewConfig.variables.config.name = name
      return updateSectionQuery(section)
    },
    ...rest,
  })

  return () => sendDialog({
    onConfirm,
    title: 'Change Name',
    content: (
      <Input name="name" defaultValue={sharedSection.previewConfig.variables.config.name} label="New Section Name" placeholder="My Section" required />
    ),
    confirmText: 'Save Name',
  })
}

const useDeleteSharedSection = ({ sharedSection, ...rest }) => {
  const sendDialog = useDialog()

  const { onSubmit: onConfirm } = useFormQuery({
    query: deleteSharedSectionQuery(sharedSection),
    ...rest,
  })

  return () => (
    sendDialog({
      onConfirm,
      title: 'Delete Shared Section?',
      content: `Are you sure you want to delete "${sharedSection.name}". All templates containing this section will be unlinked. This action cannot be undone.`,
      confirmText: 'Delete Section',
      destructive: true,
    })
  )
}

const useSaveToSharedSection = () => {
  const sendDialog = useDialog()
  const { Project } = useProject()
  const { onSubmit: onConfirm } = useFormQuery({
    query: ({
      section, config, name, publish,
    }) => {
      const newSection = section
      newSection.variables.config.name = name
      embedSectionTheme({ section: newSection, config })
      const rendered = renderSection({ section: newSection, templateVariables: config.variables })

      return createSharedSectionQuery({
        Project, section: rendered, name, publish,
      })
    },
  })

  return ({ section, config, publish }) => sendDialog({
    onConfirm,
    title: 'Create Shared Section',
    content: (
      <>
        <P>
          Shared Sections can be used by any template in this project.
          Changes made to a shared section will update all templates which use them.
        </P>
        <Input name="name" defaultValue={section.variables.config.name} label="Section Name" placeholder="My Section" required />
      </>
    ),
    confirmText: 'Create',
    form: {
      data: { section, config, publish },
    },
  })
}

export {
  useRevertSharedSection,
  usePublishSharedSection,
  useDeleteSharedSection,
  useSaveToSharedSection,
  useRenameSharedSection,
}
export { useCopyTheme } from '@level/components/Template/Email/useCopyTheme'
