import React from 'react'
import { Routes, Route } from 'react-router-dom'

import {
  Main, Loading, Shelf, Stack, NavTabs,
  Body, Divider, BlankSlate, Text,
  useLocalStorage, useSearchState, sortBy, templateConfigDefaults,
} from '@level'
import {
  SharedSectionsProvider, SharedSectionProvider,
  useSharedSections,
} from './provider'
import { SharedSection } from './section'
import { RevertSharedSection } from './revert'
import { TemplateCards } from '../templates/indexCards'
import { TemplateList } from '../templates/indexList'
import { ActionButton } from './sharedSectionActionButton'

const viewNavItems = [
  { icon: 'list-ul', label: 'List view', state: 'list' },
  { icon: 'grid2', label: 'Card view', state: 'cards' },
]

const Index = () => {
  const { sharedSections, isLoading, refresh } = useSharedSections()
  const [view, setView] = useLocalStorage('template-view', 'list')
  const [searchState, setSearchState] = useSearchState()
  const View = !view || view === 'list' ? TemplateList : TemplateCards

  React.useLayoutEffect(() => {
    if (view !== searchState.view) {
      setSearchState.merge({ view })
    }
  }, [searchState.view])

  const sections = React.useMemo(() => {
    if (!sharedSections?.length) {
      return null
    }
    // Shim compatability with template card and list expectations
    return sharedSections.map((section) => ({
      ...section,
      slug: section.id,
      type: 'email',
      previewConfig: {
        ...section.previewConfig,
        sections: [section.previewConfig],
        variables: templateConfigDefaults.template,
      },
    })).sort(sortBy('id'))
  }, [sharedSections])

  return (
    <Main>
      <Stack gap={5} space={8} style={{ backgroundColor: '#fff' }}>
        <Shelf valign="center" align="split">
          <Text tag="h2">Shared Sections</Text>
          <NavTabs
            setState={(v) => { setSearchState.merge({ view: v }); setView(v) }}
            state={view}
            space={0}
            theme="radio"
            navItems={viewNavItems}
          />
        </Shelf>
      </Stack>
      <Divider />
      <Body scroll>
        {isLoading ? <Loading /> : null}
        {!sharedSections.length ? (
          <BlankSlate
            icon="shared-section"
            heading="No Shared Sections"
            description="You can easily create a Shared Section by saving any section within a template to reuse in multiple templates and manage them here."
          />
        ) : null}
        {sharedSections?.length ? (
          <View
            refresh={refresh}
            ActionButton={ActionButton}
            templates={sections}
            watermark={false}
            path="shared-section"
          />
        ) : null}
      </Body>
    </Main>
  )
}

const SharedSectionsIndex = () => (
  <Routes>
    <Route index element={<SharedSectionsProvider><Index /></SharedSectionsProvider>} />
    <Route path="revert/:sharedSectionId" element={<SharedSectionProvider><RevertSharedSection /></SharedSectionProvider>} />
    <Route path="shared-section/:sharedSectionId/*" element={<SharedSectionProvider><SharedSection /></SharedSectionProvider>} />
  </Routes>
)

export {
  SharedSectionsIndex,
}
