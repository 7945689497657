import PropTypes from 'prop-types'
import { SelectMenu } from '../../../../Select'
import { Text } from '../../../../Text'

import { titleize } from '../../../../../helpers/text'
import { fontFamilies } from '../defaults'

const options = Object.entries(fontFamilies).map(([name, val]) => (
  {
    text: <Text style={{ fontFamily: val }}>{titleize(name)}</Text>,
    value: name,
  }
))

const FontFamilyInput = ({ value, ...props }) => (
  <SelectMenu {...props} options={options} defaultValue={value} sameWidth />
)

FontFamilyInput.propTypes = {
  value: PropTypes.string,
}

export {
  FontFamilyInput
}
