import getCaretCoordinates from 'textarea-caret'

const alphanumericRegex = /[a-zA-Z0-9-]/
const isAlphanumeric = (char) => alphanumericRegex.test(char)

export function getTriggerOffset(element, triggers, defaultToAlphanumeric = false) {
  const { value, selectionStart } = element;
  for (let i = selectionStart; i >= 0; i--) {
    const char = value[i];
    if (
      // If trigger matches
      char && triggers.includes(char)
      // Test that we're using alphanumeric matching
      || defaultToAlphanumeric && isAlphanumeric(char)
      // Does this pass and is the previous character not alphanumeric?
      // example: 'testing this' we want the position of the 't' in 'this'
      // because it follows a nonalpha character
      && (i === 0 || !isAlphanumeric(value[i - 1])
      )
    ) {
      return i
    }
  }
  return -1
}

export function getTrigger(element, triggers, defaultToAlphanumeric = false) {
  const { value, selectionStart } = element;
  const previousChar = value[selectionStart - 1];

  if (defaultToAlphanumeric) {
    if (previousChar && isAlphanumeric(previousChar)) {
      const beforeChar = value[selectionStart - 2];
      if (!beforeChar || /\s/.test(beforeChar)) {
        return previousChar;
      }
    }
    return null;
  }

  for (const trigger of triggers) {
    const start = selectionStart - trigger.length;
    if (start < 0) continue;
    if (value.slice(start, selectionStart) === trigger) {
      const beforeTrigger = value[start - 1];
      if (!beforeTrigger || /\s/.test(beforeTrigger)) return trigger;
    }
  }
  return null;
}

export function getSearchValue(element, triggers, defaultToAlphanumeric = false) {
  const offset = getTriggerOffset(element, triggers, defaultToAlphanumeric);
  if (offset === -1) return "";
  const trigger = getTrigger(element, triggers, defaultToAlphanumeric);
  if (defaultToAlphanumeric) {
    return element.value.slice(offset, element.selectionStart);
  }
  return element.value.slice(offset + (trigger?.length || 1), element.selectionStart);
}

export const getAnchorRect = (element, triggers, defaultToAlphanumeric = false) => {
  const offset = getTriggerOffset(element, triggers, defaultToAlphanumeric)
  const { left, top, height } = getCaretCoordinates(element, offset + 1)
  const { x, y } = element.getBoundingClientRect()
  return { x: left + x - element.scrollLeft, y: top + y - element.scrollTop, height }
}

export const replaceValue = (offset, searchValue, displayValue, separator = ' ') => {
  return (prevValue) => {
    return `${prevValue.slice(0, offset) + displayValue}${separator}${prevValue.slice(offset + searchValue.length + 1)}`
  }
}
