import {
  Stack, Input, AutoCompleteInput,
  P, copyTemplate,
} from '@level'

import {
  useProject, useFormQuery, useFormDialog, useQuery, useTemplateTags,
} from '@app/hooks'
import { usePasteTheme as usePasteThemeHook } from '@level/components/Template/Email/usePasteTheme'

import {
  createTemplateQuery,
  updateTemplateAttributesQuery,
  updateTemplateConfigQuery,
  deleteTemplateQuery,
  publishTemplateQuery,
  revertTemplateQuery,
} from './queries'

const usePasteTheme = ({ template }) => {
  const pasteThemeFn = usePasteThemeHook({ template })
  const [update] = useQuery(updateTemplateConfigQuery)
  const pasteTheme = async () => {
    const config = pasteThemeFn()
    await update({ id: template.id, previewConfig: config })
  }

  return (pasteThemeFn) ? pasteTheme : null
}

const useCopyTemplate = (hookProps) => {
  const { Project } = useProject()

  const sendDialog = useFormDialog({
    query: ({ name, previewConfig: config, type, tags }) => (
      createTemplateQuery({
        config: copyTemplate({ config, type }).config,
        name,
        type,
        tags,
        Project,
      })
    ),
    ...hookProps,
  })

  return ({ data, ...rest }) => sendDialog({
    title: 'Duplicate Template',
    confirmText: 'Duplicate Template',
    data,
    content: (
      <Stack gap={5}>
        <P>
          You are about to create a copy of the template: {data.name}.
        </P>
        <Input
          name="name"
          label="Template name"
          placeholder="My Template"
          defaultValue={`${data.name} (Copy)`}
          required
        />
      </Stack>
    ),
    ...rest,
  })
}

const useRevertTemplate = (hookProps) => {
  const sendDialog = useFormDialog({
    query: revertTemplateQuery,
    ...hookProps,
  })

  return ({ data, ...rest }) => sendDialog({
    title: 'Revert Changes?',
    content: `This will revert all changes to the "${data.name}" template since the last publish. Are you sure?`,
    confirmText: 'Revert Changes',
    destructive: true,
    data,
    ...rest,
  })
}

const usePublishTemplate = () => {
  const { onSubmit } = useFormQuery({
    query: publishTemplateQuery,
  })
  return onSubmit
}

const useEditTemplateTags = (hookProps) => {
  const { getTags, refresh: refreshTags } = useTemplateTags()
  const sendDialog = useFormDialog({
    query: updateTemplateAttributesQuery,
    ...hookProps,
  })

  return ({ data, hooks, ...rest }) => sendDialog({
    title: 'Manage Tags',
    confirmText: 'Save Changes',
    content: () => (
      <Stack gap={6}>
        <AutoCompleteInput
          name="tags"
          // using Get tags and a function so that it executes on run and gets the latest data
          list={getTags()?.map(t => ({ value: t.name, listValue: t.name }))}
          separator=""
          descriptionAfter="Example: one-tag, another-tag, etc."
          label="Template Tags"
          defaultValue={data.tags.map(({ name }) => name).join(', ')}
        />
      </Stack>
    ),
    data,
    hooks: {
      ...hooks,
      onSuccess: async (...args) => {
        await hooks.onSuccess?.(...args)
        // Update tags list incase new tags have been added
        refreshTags()
      },
    },
    ...rest,
  })
}


const useRenameTemplate = (hookProps) => {
  const sendDialog = useFormDialog({
    query: updateTemplateAttributesQuery,
    ...hookProps,
  })

  return ({ data, ...rest }) => sendDialog({
    title: 'Rename Template',
    confirmText: 'Save Changes',
    content: (
      <Stack gap={6}>
        <Input name="name" label="Template Name" defaultValue={data.name} />
        <Input
          name="slug"
          label="Template Slug"
          defaultValue={data.slug}
          description="Slug must be a unique. If you change this be sure to update your app's code."
        />
      </Stack>
    ),
    data,
    ...rest,
  })
}

const useDeleteTemplate = (hookProps) => {
  const sendDialog = useFormDialog({
    query: deleteTemplateQuery,
    ...hookProps,
  })

  return (props) => (
    sendDialog({
      confirmText: 'Delete',
      title: 'Delete Template',
      content: 'You are about to delete this template. Are you sure?',
      destructive: true,
      ...props,
    })
  )
}

export {
  useRenameTemplate,
  useCopyTemplate,
  useDeleteTemplate,
  usePublishTemplate,
  useRevertTemplate,
  usePasteTheme,
  useEditTemplateTags,
}

export { useCopyTheme } from '@level/components/Template/Email/useCopyTheme'
