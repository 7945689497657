import { create } from 'zustand'

const stateChannel = (channel) => create((set) => ({
  [channel]: [],
  publish: (item) => set((state) => ({
    [channel]: [...state[channel], item]
  })),
  remove: ({ index, id } = {}) => set((state) => {
    if (typeof id !== 'undefined') {
      return ({
        [channel]: state[channel].filter(item => item.id !== id)
      })
    }
    const itemIndex = Number.isFinite(index)
      ? index
      : state[channel].length - 1
    return ({
      [channel]: state[channel].filter((_, i) => i !== itemIndex)
    })
  }),
  clear: () => set(() => ({ [channel]: null })),
}))

export { stateChannel }
